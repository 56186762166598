import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import BackgroundImage from "gatsby-background-image-es5";
import About from "../img/aboutthebuckhorn.svg";
import Content, { HTMLContent } from "../components/Content";
import { motion } from "framer-motion";

export const AboutPageTemplate = ({ title, content, contentComponent }) => {
	const PageContent = contentComponent || Content;

	return (
		<>
			<section className="section section--gradient">
				<div className="container">
					<div className="columns">
						<div className="column is-8 is-offset-2">
							<motion.div
								style={{ originY: 1 }}
								initial="hidden"
								animate="visible"
								variants={{
									hidden: {
										scale: 0.8,
										opacity: 0,
									},
									visible: {
										scale: 1,
										opacity: 1,
										transition: {
											delay: 0.8,
										},
									},
								}}
							>
								<div className="">
									<h2 className="title is-size-3 has-text-weight-bold is-bold-light">
										{title}
									</h2>
								</div>

								<PageContent className="content" content={content} />
							</motion.div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

AboutPageTemplate.propTypes = {
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string.isRequired,
	content: PropTypes.string,
	contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
	const { markdownRemark: post } = data;

	return (
		<Layout>
			<BackgroundImage
				fluid={post.frontmatter.image.childImageSharp.fluid}
				className="full-width-image-container margin-top-0 mb0"
			>
				<div
					className="mx-auto relative"
					style={{
						backgroundSize: "cover",
						backgroundPosition: "top center",
						background:
							"linear-gradient(to right, rgba(0,0,0,0.6), rgba(0,0,0,0.1))",
						height: "100%",
						width: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
						padding: "100px 0",
					}}
				>
					<motion.div
						style={{ originY: 1 }}
						initial="hidden"
						animate="visible"
						variants={{
							hidden: {
								scale: 0.8,
								opacity: 0,
							},
							visible: {
								scale: 1,
								opacity: 1,
								transition: {
									delay: 0.6,
								},
							},
						}}
					>
						<div
							className="has-text-weight-bold is-size-1"
							style={{
								backgroundColor: "rgb(166, 33, 3)",
								color: "white",
								padding: "1rem",
							}}
						>
							<div
								style={{
									display: "flex",
									lineHeight: "1",
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "column",
									padding: "0px",
									height: "auto",
									position: "relative",
								}}
							>
								<img
									src={About}
									alt="Buckhorn Supper Club"
									className="mb0 svg-logo-menu"
								/>
							</div>
						</div>
					</motion.div>
				</div>
			</BackgroundImage>
			<AboutPageTemplate
				image={post.frontmatter.image}
				contentComponent={HTMLContent}
				title={post.frontmatter.title}
				content={post.html}
			/>
		</Layout>
	);
};

AboutPage.propTypes = {
	data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
	query AboutPage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			html
			frontmatter {
				title
				image {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
	}
`;
